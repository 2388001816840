import React from 'react';
import { generatePath, Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';

import { Session } from './models/session';
import { getCurrentUser } from './api/session';
import { AppLoader } from './components/loader/app-loader';
import { PATH, SLUG } from './constants/path';
import { AppContext, AppContextValue } from './app-context';

import ltLocale from 'antd/lib/locale/lt_LT';
import enLocale from 'antd/lib/locale/en_US';

const PublicPage = React.lazy(() => import('./pages/public-page/public-page'));
const LoginPage = React.lazy(() => import('./pages/login-page/login-page'));
const PrivatePage = React.lazy(
  () => import('./pages/private-page/private-page')
);
const CustomerPage = React.lazy(
  () => import('./pages/customer-page/customer-page')
);
const DocumentPage = React.lazy(
  () => import('./pages/document-page/document-page')
);
const DocumentPreviewPage = React.lazy(
  () => import('./pages/document-preview-page/document-preview-page')
);
const DocumentPreviewListPage = React.lazy(
  () => import('./pages/document-preview-list-page/document-preview-list-page')
);
const BalancePage = React.lazy(
  () => import('./pages/balance-page/balance-page')
);
const TemplatePage = React.lazy(
  () => import('./pages/template-page/template-page')
);
const RulePage = React.lazy(() => import('./pages/rule-page/rule-page'));
const TagPage = React.lazy(() => import('app/pages/tag-page/tag-page'));
const NotificationPage = React.lazy(
  () => import('./pages/notification-page/notification-page')
);
const SettingsPage = React.lazy(
  () => import('./pages/settings-page/settings-page')
);
const ConfigPage = React.lazy(
  () => import('./pages/settings-page/config-page')
);
const UsersPage = React.lazy(() => import('./pages/settings-page/users-page'));

export const App: React.FC = () => {
  const { i18n } = useTranslation();
  const [user, setUser] = React.useState<Session | null>(null);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const appContext = React.useMemo<AppContextValue>(
    () => ({
      session: user,
    }),
    [user]
  );

  React.useEffect(() => {
    getCurrentUser()
      .then(setUser)
      .catch(() => undefined)
      .finally(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) {
    return <AppLoader />;
  }

  return (
    <AppContext.Provider value={appContext}>
      <ConfigProvider
        locale={i18n.resolvedLanguage === 'lt' ? ltLocale : enLocale}
      >
        <Routes>
          <Route
            index={true}
            element={
              <Navigate
                to={
                  user
                    ? generatePath(PATH.CUSTOMER_PAGE)
                    : generatePath(PATH.LOGIN_PAGE)
                }
              />
            }
          />
          <Route element={<PublicPage />}>
            <Route path={SLUG.LOGIN} element={<LoginPage />} />
            <Route path={SLUG.DOCUMENT_PREVIEW}>
              <Route path={SLUG.TOKEN}>
                <Route index={true} element={<DocumentPreviewPage />} />
                <Route
                  path={SLUG.DOCUMENT_PREVIEW_LIST}
                  element={<DocumentPreviewListPage />}
                />
              </Route>
            </Route>
          </Route>
          <Route
            element={
              user ? (
                <PrivatePage user={user} />
              ) : (
                <Navigate to={generatePath(PATH.LOGIN_PAGE)} replace={true} />
              )
            }
          >
            <Route
              index={true}
              element={
                <Navigate
                  to={generatePath(PATH.CUSTOMER_PAGE)}
                  replace={true}
                />
              }
            />
            <Route path={SLUG.CUSTOMER} element={<CustomerPage />} />
            <Route path={SLUG.DOCUMENT} element={<DocumentPage />} />
            <Route path={SLUG.BALANCE} element={<BalancePage />} />
            <Route path={SLUG.TEMPLATE} element={<TemplatePage />} />
            <Route path={SLUG.RULE} element={<RulePage />} />
            <Route path={SLUG.TAG} element={<TagPage />} />
            <Route path={SLUG.NOTIFICATION} element={<NotificationPage />} />
            <Route path={SLUG.SETTINGS} element={<SettingsPage />}>
              <Route index={true} element={<ConfigPage />} />
              <Route path={SLUG.USERS} element={<UsersPage />} />
            </Route>
          </Route>
        </Routes>
      </ConfigProvider>
    </AppContext.Provider>
  );
};
