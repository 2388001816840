import React from 'react';

import { Session } from './models/session';

export interface AppContextValue {
  session: Session | null;
}

export const AppContext = React.createContext<AppContextValue>({
  session: null,
});
